import { v4 as uuidv4 } from 'uuid';
import img5 from '../../assets/img/portfolio/cases1.jpg';

import img1 from '../../assets/img/portfolio/asbestos/0.jpg';
import img2 from '../../assets/img/portfolio/asbestos/1.jpg';
import img3 from '../../assets/img/portfolio/asbestos/2.jpg';
import img4 from '../../assets/img/portfolio/asbestos/3.jpg';
import img6 from '../../assets/img/portfolio/asbestos/4.jpg';
import img7 from '../../assets/img/portfolio/asbestos/5.jpg';

import img8 from '../../assets/img/portfolio/demolition/0.jpg';
import img9 from '../../assets/img/portfolio/demolition/1.jpg';
import img10 from '../../assets/img/portfolio/demolition/2.jpg';
import img11 from '../../assets/img/portfolio/demolition/3.jpg';
import img12 from '../../assets/img/portfolio/demolition/4.jpg';

import img13 from '../../assets/img/portfolio/lead/0.jpg';
import img14 from '../../assets/img/portfolio/lead/1.jpg';
import img15 from '../../assets/img/portfolio/lead/2.jpg';
import img16 from '../../assets/img/portfolio/lead/3.jpg';
import img17 from '../../assets/img/portfolio/lead/4.jpg';
import img18 from '../../assets/img/portfolio/lead/5.jpg';
import img19 from '../../assets/img/portfolio/lead/6.jpg';


import img20 from '../../assets/img/portfolio/mold/0.jpg';
import img21 from '../../assets/img/portfolio/mold/1.jpg';
import img22 from '../../assets/img/portfolio/mold/2.jpg';
import img23 from '../../assets/img/portfolio/mold/3.jpg';

import img24 from '../../assets/img/portfolio/pipeinsulation/0.jpg';
import img25 from '../../assets/img/portfolio/pipeinsulation/1.jpg';
import img26 from '../../assets/img/portfolio/pipeinsulation/2.jpg';
import img27 from '../../assets/img/portfolio/pipeinsulation/3.jpg';


import img46 from '../../assets/img/portfolio/pipeinsulation/4.jpeg';
import img47 from '../../assets/img/portfolio/pipeinsulation/5.jpeg';
import img48 from '../../assets/img/portfolio/pipeinsulation/6.jpeg';
import img49 from '../../assets/img/portfolio/pipeinsulation/7.jpeg';
import img50 from '../../assets/img/portfolio/pipeinsulation/8.jpeg';
import img51 from '../../assets/img/portfolio/pipeinsulation/9.jpeg';
import img52 from '../../assets/img/portfolio/pipeinsulation/10.jpeg';
import img53 from '../../assets/img/portfolio/pipeinsulation/11.jpeg';


import img28 from '../../assets/img/portfolio/pipescaffolding/0.jpg';
import img29 from '../../assets/img/portfolio/pipescaffolding/1.jpg';
import img30 from '../../assets/img/portfolio/pipescaffolding/2.jpg';
import img31 from '../../assets/img/portfolio/pipescaffolding/3.jpg';

import img32 from '../../assets/img/portfolio/restorationsupport/0.jpg';
import img33 from '../../assets/img/portfolio/restorationsupport/1.jpg';
import img34 from '../../assets/img/portfolio/restorationsupport/2.jpg';
import img35 from '../../assets/img/portfolio/restorationsupport/3.jpg';
import img36 from '../../assets/img/portfolio/restorationsupport/4.jpg';
import img37 from '../../assets/img/portfolio/restorationsupport/5.jpg';
import img38 from '../../assets/img/portfolio/restorationsupport/6.jpg';
import img39 from '../../assets/img/portfolio/restorationsupport/7.jpg';


import img40 from '../../assets/img/portfolio/waterfire/0.jpg';
import img41 from '../../assets/img/portfolio/waterfire/1.jpg';
import img42 from '../../assets/img/portfolio/waterfire/2.jpg';
import img43 from '../../assets/img/portfolio/waterfire/3.jpg';
import img44 from '../../assets/img/portfolio/waterfire/4.jpg';
import img45 from '../../assets/img/portfolio/waterfire/5.jpg';

import img58 from '../../assets/img/portfolio/waterfire/6.jpeg';
import img59 from '../../assets/img/portfolio/waterfire/7.jpeg';
import img60 from '../../assets/img/portfolio/waterfire/8.jpeg';
import img61 from '../../assets/img/portfolio/waterfire/9.jpeg';


import img54 from '../../assets/img/portfolio/pipeinstallation/0.jpeg';
import img55 from '../../assets/img/portfolio/pipeinstallation/1.jpeg';
import img56 from '../../assets/img/portfolio/pipeinstallation/2.jpeg';
import img57 from '../../assets/img/portfolio/pipeinstallation/3.jpeg';




const projectFilterTwoData = [
    {
        id: uuidv4(),
        image: img1,
        name: '',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },

    {
        id: uuidv4(),
        image: img2,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },

    {
        id: uuidv4(),
        image: img3,
        name: '',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },

    {
        id: uuidv4(),
        image: img4,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },


    {
        id: uuidv4(),
        image: img6,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },

    {
        id: uuidv4(),
        image: img7,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Asbestos Pipe Glove Bags',
        category: 'asbestos',
    },

    {
        id: uuidv4(),
        image: img8,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Demolition Service',
        category: 'demolition',
    },

    {
        id: uuidv4(),
        image: img9,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Demolition Service',
        category: 'demolition',
    },

    {
        id: uuidv4(),
        image: img10,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Demolition Service',
        category: 'demolition',
    },


    {
        id: uuidv4(),
        image: img11,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Demolition Service',
        category: 'demolition',
    },


    {
        id: uuidv4(),
        image: img12,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Demolition Service',
        category: 'demolition',
    },

    {
        id: uuidv4(),
        image: img13,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },

    {
        id: uuidv4(),
        image: img14,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },

    {
        id: uuidv4(),
        image: img15,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },

    {
        id: uuidv4(),
        image: img16,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },

    {
        id: uuidv4(),
        image: img17,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },

    {
        id: uuidv4(),
        image: img18,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },
    {
        id: uuidv4(),
        image: img19,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Lead Removal',
        category: 'lead',
    },


    {
        id: uuidv4(),
        image: img20,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Mold Remediation',
        category: 'mold',
    },

    {
        id: uuidv4(),
        image: img21,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Mold Remediation',
        category: 'mold',
    },
    {
        id: uuidv4(),
        image: img22,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Mold Remediation',
        category: 'mold',
    },

    {
        id: uuidv4(),
        image: img23,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Mold Remediation',
        category: 'mold',
    },

    {
        id: uuidv4(),
        image: img24,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },

    {
        id: uuidv4(),
        image: img25,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
  
    {
        id: uuidv4(),
        image: img26,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
  

    {
        id: uuidv4(),
        image: img27,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },

    {
        id: uuidv4(),
        image: img46,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img47,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img48,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img49,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img50,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img51,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img52,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img53,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },


    {
        id: uuidv4(),
        image: img54,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    
    {
        id: uuidv4(),
        image: img55,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img56,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },
    {
        id: uuidv4(),
        image: img57,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Insulation Removals And Set Up',
        category: 'pipeinsulation',
    },










    
    {
        id: uuidv4(),
        image: img28,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Scaffolding',
        category: 'pipescaffolding',
    },
  

    {
        id: uuidv4(),
        image: img29,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Scaffolding',
        category: 'pipescaffolding',
    },
  

    {
        id: uuidv4(),
        image: img30,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Scaffolding',
        category: 'pipescaffolding',
    },
  

    {
        id: uuidv4(),
        image: img31,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Pipe Scaffolding',
        category: 'pipescaffolding',
    },
  

    {
        id: uuidv4(),
        image: img32,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  

    {
        id: uuidv4(),
        image: img33,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  

    {
        id: uuidv4(),
        image: img34,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img35,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img36,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img37,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img38,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img39,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Restoration Support',
        category: 'restoration',
    },
  
    {
        id: uuidv4(),
        image: img40,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
  
    {
        id: uuidv4(),
        image: img41,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
  
    {
        id: uuidv4(),
        image: img42,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
  
    {
        id: uuidv4(),
        image: img43,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
  
    {
        id: uuidv4(),
        image: img44,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
  
    {
        id: uuidv4(),
        image: img45,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },


    {
        id: uuidv4(),
        image: img58,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },

    
    {
        id: uuidv4(),
        image: img59,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
    
    {
        id: uuidv4(),
        image: img60,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },
    
    {
        id: uuidv4(),
        image: img61,
        name: ' ',
        price: 'TRM STAFFING LLC',
        heading: 'Water & Fire Clean',
        category: 'waterfire',
    },





  
    



    

];

export default projectFilterTwoData;
