import React from 'react';
import { BsArrowRight, BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import SealImg from '../../assets/img/home1/seal.png';
import IndianaRatingBureau from '../../assets/img/media/indiana-cooperation-bureau.webp';
import NCCIImg from '../../assets/img/media/ncci.webp';
import {Fade} from 'react-reveal';

function Promo() {
    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <Fade left>
                    <div className="col-xl-6 col-12 text-center">
                        <div className="row">
                            <div className="col-xl-6 col-12 pb-10">
                                <img src={IndianaRatingBureau} style={{width: '200px', height: '150px'}} alt="Indiana Compensation Rating Bureau"/>
                            </div>
                            <div className="col-xl-6 col-12" style={{marginBottom: '20px'}}>
                                <img src={NCCIImg} style={{width: '200px', height: '150px'}} alt="NCCI"/>
                            </div>
                            <div className="col-12 pt-5">
                                <img src={SealImg} alt="age" style={{width: '200px', height: '200px'}}/>
                                <h5 style={{padding: '1em'}}>
                                    Certified by the <b>State of Indiana</b>
                                </h5>
                            </div>
                        </div>

                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-xl-6 col-12">
                        <div className="block-contents ml-xl-5 mt-5 mt-xl-0">
                        <span>Best professionals in the field</span>
                            <h1>Job Guaranteed, Insured & Certified</h1>
                            <h4>
                                Trust your project to experts in the field with years of experience and and the best toolsets for the job.
                            </h4>
                            <Link to="/contact" className="theme-btn">
                                Contact Us
                                <BsArrowRight style={{ fontSize: '20px', marginLeft: '15px' }} />
                            </Link>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
}

export default Promo;
