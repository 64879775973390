import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FaRegComments } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function SinglePost() {
    const location = useLocation();

    const {state} = location || {};

    console.log(state);
    return (
        <div className="single-blog-post post-details">
            <div className="post-content">
                <div className="post-cat">
                    <a href="news.html">{state.category}</a>
                </div>
                <h2>{state.title}</h2>
                <div className="post-meta">
                    <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span>
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        24th March 2019
                    </span>
                </div>
                <div dangerouslySetInnerHTML={{__html: state.content}}></div>
            </div>
        </div>
    );
}

export default SinglePost;
