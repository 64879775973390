import { v4 as uuidv4 } from 'uuid';
import Bg1 from '../../assets/img/media/services/service1.webp';
import Bg2 from '../../assets/img/media/services/service2.webp';
import Bg3 from '../../assets/img/media/services/service3.webp';
import Bg4 from '../../assets/img/media/services/service4.webp';
import Bg5 from '../../assets/img/media/services/service5.webp';
import Bg6 from '../../assets/img/media/services/service6.jpeg';
import Bg7 from '../../assets/img/media/services/service7.webp';
import Bg8 from '../../assets/img/media/services/service8.webp';
import Icon1 from '../../assets/img/icon/s1.png';
import Icon2 from '../../assets/img/icon/s2.png';
import Icon3 from '../../assets/img/icon/s3.png';
import Icon4 from '../../assets/img/icon/s4.png';
import Icon5 from '../../assets/img/icon/s5.png';
import Icon6 from '../../assets/img/icon/s6.png';
import PipeIcon from '../../assets/img/icon/pipes.png';




const servicesOneData = [
  {
    id: uuidv4(),
    bgImg: Bg1,
    icon: Icon1,
    heading: 'Asbestos Abatement',
    description: `Ensuring safety and compliance

    <br/>
    <b>Inspection and Assessment:</b> Identifying asbestos presence.
    <br/>
    <b>Customized Plan:</b> Safe removal or encapsulation.
    <br/>
    <b>Safe Removal:</b> Certified experts handle asbestos.
    <br/>
    <b>Cleaning and Decontamination:</b> Thorough cleaning for complete safety.
    <br/>
    <b>Air Quality Testing:</b> Certification for asbestos-free space.`,
    btnText: 'More Info',
  },

  {
    id: uuidv4(),
    bgImg: Bg2,
    icon: Icon2,
    heading: 'Lead Removal',
    description: `Ensuring safety and compliance
    <br/>

    <b>Inspection and Assessment:</b> Identifying lead presence.
    <br/>
    <b>Customized Plan:</b> Safe removal or encapsulation.
    <br/>
    <b>Certified Experts:</b> Handling lead removal.
    <br/>
    <b>Thorough Cleaning:</b> Complete decontamination for safety.
    <br/>
    <b>Quality Assurance:</b> Certification for lead-free space.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg3,
    icon: Icon3,
    heading: 'Mold Remediation',
    description: `Restoring safety and health
    <br/>
    <b>Assessment:</b> Identifying mold presence.
    <br/>
    <b>Customized Plan:</b> Safe removal or remediation.
    <br/>
    <b>Expert Handling:</b> Certified technicians manage mold removal.
    <br/>
    <b>Thorough Cleaning:</b> Complete mold eradication for safety.
    <br/>
    <b>Quality Assurance:</b> Certification for mold-free environment.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg4,
    icon: Icon4,
    heading: 'Demolition Services',
    description: `Building a new future
    <br/>
    <b>Site Evaluation:</b> Assessing demolition needs.
    <br/>
    <b>Customized Plan:</b> Tailored demolition strategy.
    <br/>
    <b>Experienced Crew:</b> Skilled professionals manage demolition.
    <br/>
    <b>Safe and Efficient:</b> Ensuring safety and timely completion.
    <br/>
    <b>Environmental Compliance:</b> Responsible debris disposal.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg5,
    icon: Icon5,
    heading: 'Restoration Support',
    description: `Bringing spaces back to life
    <br/>
    <b>Assessment & Planning:</b> Comprehensive evaluation and tailored restoration plans.
    <br/>
    <b>Skilled Professionals:</b> Experienced team proficient in restoration techniques.
    <br/>
    <b>Efficient Execution:</b> Timely and effective restoration work.
    <br/>
    <b>Quality Materials:</b> Utilizing top-notch materials for lasting results.
    <br/>
    <b>Customer Satisfaction:</b> Ensuring your satisfaction throughout the restoration process.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg6,
    icon: Icon6,
    heading: 'Water & Fire Damage Clean Up',
    description: `Restoring peace of mind
    <br/>
    <b>Emergency Response:</b> Rapid deployment for immediate assistance.
    <br/>
    <b>Comprehensive Assessment:</b> Evaluating water or fire damage extent.
    <br/>
    <b>Expert Cleanup Crews:</b> Skilled professionals proficient in restoration techniques.
    <br/>
    <b>Thorough Restoration:</b> Complete cleanup and restoration services.
    <br/>
    <b>Supportive Guidance:</b> Guiding you through the cleanup and restoration process.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg7,
    icon: PipeIcon,
    heading: 'Pipe Insulation Removals and Set Up',
    description: `Restoring peace of mind
    <br/>
    <b>Emergency Response:</b> Rapid deployment for immediate assistance.
    <br/>
    <b>Comprehensive Assessment:</b> Evaluating water or fire damage extent.
    <br/>
    <b>Expert Cleanup Crews:</b> Skilled professionals proficient in restoration techniques.
    <br/>
    <b>Thorough Restoration:</b> Complete cleanup and restoration services.
    <br/>
    <b>Supportive Guidance:</b> Guiding you through the cleanup and restoration process.`,
    btnText: 'More Info',
  },
  {
    id: uuidv4(),
    bgImg: Bg8,
    icon: PipeIcon,
    heading: 'Pipe Scaffolding',
    description: `Restoring peace of mind
    <br/>
    <b>Emergency Response:</b> Rapid deployment for immediate assistance.
    <br/>
    <b>Comprehensive Assessment:</b> Evaluating water or fire damage extent.
    <br/>
    <b>Expert Cleanup Crews:</b> Skilled professionals proficient in restoration techniques.
    <br/>
    <b>Thorough Restoration:</b> Complete cleanup and restoration services.
    <br/>
    <b>Supportive Guidance:</b> Guiding you through the cleanup and restoration process.`,
    btnText: 'More Info',
  },
];

export default servicesOneData;
