import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717573.6950511714!2d-87.84535037011871!3d40.079280778943186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b50bcd9f81b1d%3A0x7e102fcecb32ec72!2sIndiana%2C%20EE.%20UU.!5e0!3m2!1ses!2sni!4v1709701911422!5m2!1ses!2sni" frameBorder="0" aria-hidden="false" style={{border: "0px", width: "100%"}}></iframe>
        </div>
    );
}

export default Map;
