import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/media/blog/blog1.webp';
import thumb2 from '../../assets/img/media/blog/blog2.webp';
import thumb3 from '../../assets/img/media/blog/blog3.webp';
import thumb4 from '../../assets/img/media/blog/blog4.webp';
import thumb5 from '../../assets/img/media/blog/blog5.webp';

const blogOneData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        date: '20',
        month: 'Jun',
        category: 'Asbestos',
        author: '',
        title: 'Know more about asbestos abatement',
        link: '/service-insights',
        content: `<div>
                                <p>
                                    <u>
                                        <strong style="color: #000">Asbestos Removal from Ceiling</strong>
                                    </u>
                                </p>
                                <p>
                                    <strong style="color: #000">Unique Complexities</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Ceilings present challenges related to accessibility,
                                            potential
                                            structural implications, and the need for precise
                                            containment during
                                            asbestos removal.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            The presence of asbestos in ceilings necessitates
                                            meticulous
                                            planning to minimize disturbance and airborne fiber
                                            release during
                                            abatement.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Safety and Containment</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Certified professionals implement stringent containment
                                            measures,
                                            including HEPA filtration systems and barrier
                                            enclosures, to prevent
                                            asbestos dispersion.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Adherence to industry best practices ensures the
                                            protection of
                                            occupants and workers from asbestos exposure during
                                            ceiling removal
                                            operations.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Inspection and Testing</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Thorough inspection and testing of ceiling materials are
                                            essential
                                            for identifying and assessing asbestos-containing
                                            substances,
                                            guiding tailored removal approaches.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Accredited laboratories conduct sample analysis to
                                            validate the
                                            presence of asbestos in ceiling materials, informing
                                            targeted
                                            abatement strategies.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Professional Intervention</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Trained experts utilize specialized equipment and
                                            methodologies to
                                            execute safe and efficient asbestos removal from
                                            ceilings, promoting
                                            indoor air quality and occupant well-being.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <u>
                                        <strong style="color: #000">Asbestos Removal from Roof</strong>
                                    </u>
                                </p>
                                <p>
                                    <strong style="color: #000">Environmental Considerations</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Roof-related asbestos removal requires careful
                                            consideration of
                                            weather exposure, structural integrity, and ecological
                                            impact,
                                            necessitating specialized expertise.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Compliance with local regulations and waste management
                                            protocols is
                                            vital to minimize environmental repercussions during
                                            asbestos
                                            abatement from roofs.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Compliance and Standards</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Adherence to industry standards and regulatory
                                            requirements ensures
                                            the safe and lawful execution of asbestos removal from
                                            diverse
                                            roofing materials.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Professional asbestos contractors possess the expertise
                                            to navigate
                                            complex compliance frameworks and deliver comprehensive
                                            roof-related
                                            abatement solutions.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Waste Management and Disposal</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Proper disposal of asbestos-containing roofing materials
                                            adheres to
                                            established protocols, safeguarding environmental health
                                            and
                                            regulatory compliance.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Accredited disposal facilities handle the secure
                                            containment and
                                            disposal of asbestos waste, mitigating potential risks
                                            to the
                                            surrounding ecosystem.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <u>
                                        <strong style="color: #000">Asbestos Removal from Walls</strong>
                                    </u>
                                </p>
                                <p>
                                    <strong style="color: #000">Intricacies of Wall Removal</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Asbestos removal from walls requires meticulous surface
                                            preparation,
                                            containment, and disposal procedures to minimize fiber
                                            release and
                                            ensure occupant safety.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            The intricate nature of wall structures demands tailored
                                            abatement
                                            approaches, considering factors such as material
                                            composition and
                                            surface conditions.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Health and Safety Measures</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Certified professionals implement stringent safety
                                            measures,
                                            including personal protective equipment and controlled
                                            removal
                                            practices, to mitigate airborne asbestos exposure.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            The utilization of advanced containment systems and
                                            decontamination
                                            protocols minimizes the risk of cross-contamination
                                            during
                                            wall-related asbestos abatement.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Post-Removal Measures</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Clearance testing and documentation validate the
                                            efficacy of
                                            asbestos removal from walls, providing assurance of a
                                            safe and
                                            compliant indoor environment.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Professional assessment and documentation of wall
                                            abatement
                                            activities contribute to transparency and accountability
                                            in asbestos
                                            removal practices.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <u>
                                        <strong style="color: #000">Asbestos Removal from Floor</strong>
                                    </u>
                                </p>
                                <p>
                                    <strong style="color: #000">Diverse Flooring Materials</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            The presence of asbestos in various flooring materials
                                            necessitates
                                            tailored removal strategies to address the specific
                                            challenges and
                                            health implications associated with each type.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Specialized Techniques</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Certified professionals utilize specialized techniques,
                                            such as
                                            encapsulation and controlled demolition, to safely
                                            remove asbestos
                                            from different types of flooring materials.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Rigorous decontamination procedures minimize the risk of
                                            asbestos
                                            exposure during floor removal, protecting the health and
                                            well-being
                                            of occupants and workers.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Regulatory Compliance</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Compliance with regulatory requirements for asbestos
                                            removal from
                                            floors ensures the safe and lawful execution of
                                            abatement
                                            activities, promoting environmental and occupational
                                            health.
                                        </p>
                                    </li>
                                </ul>
                            </div>`
    },
    {
        id: uuidv4(),
        thumbnail: thumb2,
        date: '20',
        month: 'Jun',
        category: 'Clean Up',
        author: '',
        title: 'Specialized Clean-up Services',
        link: '/service-insights',
        content: `<div>
                                <p>
                                    <strong style="color: #000">Especialized Clean Up Service for
                                        Asbestos Removal</strong>
                                </p>
                                <p>
                                    <strong style="color: #000">Specialized Expertise</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            A dedicated Especialized Clean Up service offers
                                            expertise in the
                                            thorough removal and cleanup of hazardous asbestos
                                            materials from
                                            diverse settings, ensuring the safety of occupants and
                                            the
                                            environment.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            The specialized team possesses the knowledge and
                                            training required
                                            to execute meticulous asbestos abatement procedures in
                                            compliance
                                            with industry regulations and best practices.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Comprehensive Site Assessment</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Prior to commencing cleanup operations, the Especialized
                                            Clean Up
                                            service conducts a comprehensive site assessment to
                                            identify the
                                            extent of asbestos contamination and develop a tailored
                                            cleanup
                                            strategy.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500"> Detailed evaluation of the
                                            affected areas facilitates the implementation of
                                            targeted cleanup measures, addressing specific asbestos
                                            hazards within the environment. <a name="_GoBack"></a>
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <a name="_GoBack">
                                        <strong style="color: #000">Advanced Cleanup Techniques</strong>
                                    </a>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            <a name="_GoBack" style="font-weight: 500">
                                                Utilizing advanced cleanup techniques and equipment,
                                                the
                                                specialized team effectively removes asbestos
                                                particles and
                                                contaminated materials, minimizing the risk of fiber
                                                release and
                                                airborne exposure.
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Specialized vacuum systems, HEPA filtration, and
                                                decontamination
                                                protocols ensure thorough cleanup and the
                                                elimination of
                                                asbestos residues from surfaces and indoor spaces.
                                            </a>
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <a name="_GoBack">
                                        <strong style="color: #000">Environmental Considerations</strong>
                                    </a>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                The Especialized Clean Up service prioritizes
                                                environmental
                                                responsibility, implementing measures to prevent
                                                asbestos
                                                dispersion and ensure proper disposal of hazardous
                                                materials in
                                                accordance with regulatory guidelines.
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Emphasis on eco-friendly cleanup practices and waste
                                                management
                                                procedures mitigates the potential impact of
                                                asbestos
                                                contamination on the surrounding ecosystem.
                                            </a>
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <a name="_GoBack">
                                        <strong style="color: #000">Safety Protocols and Compliance</strong>
                                    </a>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Stringent safety protocols govern the Especialized
                                                Clean Up
                                                service's operations, encompassing personal
                                                protective
                                                equipment, containment procedures, and meticulous
                                                decontamination practices to safeguard the
                                                well-being of workers
                                                and occupants.
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Compliance with industry standards and regulatory
                                                requirements
                                                is integral to the execution of safe and lawful
                                                asbestos cleanup
                                                activities, reflecting the commitment to maintaining
                                                health and
                                                safety standards.
                                            </a>
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <a name="_GoBack">
                                        <strong style="color: #000">Documentation and Validation</strong>
                                    </a>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Thorough documentation of cleanup activities,
                                                including
                                                clearance testing results and compliance records,
                                                provides
                                                transparent validation of the effectiveness of
                                                asbestos removal
                                                and cleanup efforts.
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <a name="_GoBack" style="font-weight: 500">
                                                Comprehensive documentation contributes to
                                                accountability and
                                                assurance, offering stakeholders confidence in the
                                                successful
                                                mitigation of asbestos hazards within the cleaned
                                                environment.
                                            </a>
                                        </p>
                                    </li>
                                </ul>
                            </div>`
    },
    {
        id: uuidv4(),
        thumbnail: thumb3,
        date: '20',
        month: 'Jun',
        category: 'Demolition',
        author: '',
        title: 'Excellence in demolition services',
        link: '/service-insights',
        content: `<div>
                                <p>
                                    <strong style="color: #000">Demolition Services with Asbestos
                                        Removal Expertise</strong>
                                </p>
                                <p>
                                    <strong style="color: #000">Asbestos Survey and Assessment</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Prior to demolition activities, the specialized
                                            Demolition Services
                                            conduct a comprehensive asbestos survey to identify the
                                            presence of
                                            hazardous materials within the structure slated for
                                            demolition.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Thorough assessment and testing enable the
                                            identification of
                                            asbestos-containing materials (ACMs) and inform the
                                            development of
                                            targeted removal strategies to ensure compliance with
                                            regulations
                                            and safety protocols.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Safe Asbestos Abatement</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Equipped with expertise in asbestos abatement, the
                                            Demolition
                                            Services implement safe and meticulous removal
                                            procedures to
                                            mitigate the risk of asbestos fiber release during
                                            demolition.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Adherence to established abatement protocols and
                                            containment
                                            measures ensures the controlled removal of
                                            asbestos-containing
                                            elements, safeguarding the surrounding environment and
                                            personnel.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Integrated Demolition Planning</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            The Demolition Services integrate asbestos removal
                                            considerations
                                            into the overall demolition planning process,
                                            orchestrating a
                                            cohesive strategy that prioritizes the safe and
                                            efficient
                                            elimination of ACMs.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Coordinated demolition and asbestos abatement efforts
                                            streamline the
                                            process, minimizing disruption and ensuring the
                                            systematic removal
                                            of hazardous materials in preparation for structural
                                            demolition.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Environmental Responsibility</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Environmental stewardship is a cornerstone of the
                                            Demolition
                                            Services' approach, encompassing responsible disposal
                                            practices and
                                            measures to prevent the release of asbestos fibers into
                                            the
                                            atmosphere.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Diligent waste management and adherence to environmental
                                            regulations
                                            reflect the commitment to minimizing the ecological
                                            impact of
                                            demolition-related activities, including asbestos
                                            removal.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Safety Protocols and Compliance</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            Stringent safety protocols govern the Demolition
                                            Services'
                                            operations, encompassing personal protective equipment,
                                            dust control
                                            measures, and decontamination procedures to safeguard
                                            workers and
                                            the surrounding community.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Compliance with regulatory guidelines and industry
                                            standards
                                            underscores the commitment to executing demolition and
                                            asbestos
                                            removal activities in a safe, lawful, and ethical
                                            manner.
                                        </p>
                                    </li>
                                </ul>
                                <p>
                                    <strong style="color: #000">Documentation and Clearance
                                        Verification</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p style="font-weight: 500">
                                            The Demolition Services maintain meticulous
                                            documentation of
                                            asbestos removal activities, including post-abatement
                                            inspections
                                            and clearance testing, to validate the successful
                                            elimination of
                                            asbestos hazards.
                                        </p>
                                    </li>
                                    <li>
                                        <p style="font-weight: 500">
                                            Transparent documentation and validation processes
                                            provide assurance
                                            to stakeholders, affirming the thoroughness of asbestos
                                            removal
                                            efforts and the readiness for safe demolition
                                            proceedings.
                                        </p>
                                    </li>
                                </ul>
                            </div>`
    },
    {
        id: uuidv4(),
        thumbnail: thumb4,
        date: '20',
        month: 'Jun',
        category: 'Pipe Insulation',
        author: '',
        title: 'Pipe Insulation Setting Up Labors',
        link: '/service-insights',
        content: `<div>
                                <style type="text/css">
                                    p.p1 {
                                    margin: 0.0px 0.0px 4.2px 0.0px;
                                    font: 16.5px Helvetica;
                                    color: #18191a
                                    }

                                    p.p2 {
                                    margin: 0.0px 0.0px 0.0px 0.0px;
                                    font: 14.0px Helvetica;
                                    color: #18191a
                                    }

                                    p.p3 {
                                    margin: 0.0px 0.0px 1.4px 36.0px;
                                    text-indent: -36.0px;
                                    font: 14.0px Helvetica;
                                    color: #18191a
                                    }

                                    span.s1 {
                                    text-decoration: underline;
                                    color: #274ec0
                                    }
                                </style>
                                <p class="p1">
                                    <strong>Multilayer insulation (MLI)</strong>
                                </p>
                                <p class="p2">Multilayer insulation (MLI) is the most common passive
                                    thermal control element used on spacecraft. MLI prevents both
                                    heat losses to the environment and excessive heating from the
                                    environment. Spacecraft components such as propellant tanks,
                                    propellant lines, batteries, and solid rocket motors are also
                                    covered in MLI blankets to maintain ideal operating temperature.
                                    MLI consist of an outer cover layer, interior layer, and an
                                    inner cover layer. The outer cover layer needs to be opaque to
                                    sunlight, generate a low amount of particulate contaminates, and
                                    be able to survive in the environment and temperature to which
                                    the spacecraft will be exposed. Some common materials used for
                                    the outer layer are fiberglass woven cloth impregnated with <a
                                        href="https://en.wikipedia.org/wiki/PTFE">
                                        <span class="s1">PTFE</span>
                                    </a>
                                    Teflon, <a
                                        href="https://en.wikipedia.org/wiki/Polyvinyl_fluoride">
                                        <span class="s1">PVF</span>
                                    </a>
                                    reinforced with <a href="https://en.wikipedia.org/wiki/Nomex">
                                        <span class="s1">Nomex</span>
                                    </a>
                                    bonded with polyester adhesive, and <a
                                        href="https://en.wikipedia.org/wiki/Fluorinated_ethylene_propylene">
                                        <span class="s1">FEP</span>
                                    </a>
                                    Teflon. The general requirement for the interior layer is that
                                    it needs to have a low emittance. The most commonly used
                                    material for this layer is <a
                                        href="https://en.wikipedia.org/wiki/Mylar">
                                        <span class="s1">Mylar</span>
                                    </a>
                                    aluminized on one or both sides. The interior layers are usually
                                    thin compared to the outer layer to save weight and are
                                    perforated to aid in venting trapped air during launch. The
                                    inner cover faces the spacecraft hardware and is used to protect
                                    the thin interior layers. Inner covers are often not aluminized
                                    in order to prevent electrical shorts. Some materials used for
                                    the inner covers are <a
                                        href="https://en.wikipedia.org/wiki/Polyethylene_terephthalate">
                                        <span class="s1">Dacron</span>
                                    </a>
                                    and Nomex netting. Mylar is not used because of flammability
                                    concerns. MLI blankets are an important element of the thermal
                                    control system.</p>
                                <p class="p1">
                                    <strong>Louvers</strong>
                                </p>
                                <p class="p2">Louvers are active thermal control elements that are
                                    used in many different forms. Most commonly they are placed over
                                    external radiators, louvers can also be used to control heat
                                    transfer between internal spacecraft surfaces or be placed on
                                    openings on the spacecraft walls. A louver in its fully open
                                    state can reject six times as much heat as it does in its fully
                                    closed state, with no power required to operate it. The most
                                    commonly used louver is the bimetallic, spring-actuated,
                                    rectangular blade louver also known as venetian-blind louver.
                                    Louver radiator assemblies consist of five main elements:
                                    baseplate, blades, actuators, sensing elements, and structural
                                    elements.</p>
                                <p class="p1">
                                    <strong>Heaters</strong>
                                </p>
                                <p class="p2">Heaters are used in thermal control design to protect
                                    components under cold-case environmental conditions or to make
                                    up for heat that is not dissipated. Heaters are used with
                                    thermostats or solid-state controllers to provide exact
                                    temperature control of a particular component. Another common
                                    use for heaters is to warm up components to their minimal
                                    operating temperatures before the components are turned on.</p>
                                <p class="p3">The most common type of heater used on spacecraft is
                                    the patch heater, which consists of an electrical-resistance
                                    element sandwiched between two sheets of flexible electrically
                                    insulating material, such as <a
                                        href="https://en.wikipedia.org/wiki/Kapton">
                                        <span class="s1">Kapton</span>
                                    </a>.
                                    The patch heater may contain either a single circuit or multiple
                                    circuits, depending on whether or not redundancy is required
                                    within it.</p>
                                <p class="p3">Another type of heater, the <a
                                        href="https://en.wikipedia.org/wiki/Cartridge_heater">
                                        <span class="s1">cartridge heater</span>
                                    </a>,
                                    is often used to heat blocks of material or high-temperature
                                    components such as propellants. This heater consists of a coiled
                                    resistor enclosed in a cylindrical metallic case. Typically a
                                    hole is drilled in the component to be heated, and the cartridge
                                    is potted into the hole. Cartridge heaters are usually a
                                    quarter-inch or less in diameter and up to a few inches long.</p>
                                <p class="p3">Another type of heater used on spacecraft is the
                                    radioisotope heater units also known as RHUs. RHUs are used for
                                    travelling to outer planets past Jupiter due to very low solar
                                    radiance, which greatly reduces the power generated from solar
                                    panels. These heaters do not require any electrical power from
                                    the spacecraft and provide direct heat where it is needed. At
                                    the center of each RHU is a radioactive material, which decays
                                    to provide heat. The most commonly used material is <a
                                        href="https://en.wikipedia.org/wiki/Plutonium_dioxide">
                                        <span class="s1">plutonium dioxide</span>
                                    </a>.
                                    A single RHU weighs just 42 grams and can fit in a cylindrical
                                    enclosure 26 mm in diameter and 32 mm long. Each unit also
                                    generates 1 W of heat at encapsulation, however the heat
                                    generation rate decreases with time. A total of 117 RHUs were
                                    used on the <a
                                        href="https://en.wikipedia.org/wiki/Cassini-Huygens">
                                        <span class="s1">Cassini</span>
                                    </a>
                                    mission.</p>
                            </div>`
    },
    {
        id: uuidv4(),
        thumbnail: thumb5,
        date: '20',
        month: 'Jun',
        category: 'Pipe Insulation',
        author: '',
        title: 'Metal Tanks Insulation Removal Labors',
        link: '/service-insights',
        content: `<div>
                                <style type="text/css">
                                    p.p1 {
                                    margin: 0.0px 0.0px 0.0px 0.0px;
                                    font: 14.0px Helvetica;
                                    color: #18191a
                                    }

                                    li.li2 {
                                    margin: 0.0px 0.0px 1.4px 0.0px;
                                    font: 14.0px Helvetica;
                                    color: #18191a
                                    }

                                    span.s1 {
                                    font-kerning: none;
                                    background-color: #ffffff
                                    }

                                    span.s2 {
                                    font: 11.2px Helvetica;
                                    font-kerning: none;
                                    color: #274ec0;
                                    background-color: #ffffff
                                    }

                                    span.s3 {
                                    font-kerning: none;
                                    color: #274ec0
                                    }

                                    span.s4 {
                                    background-color: #ffffff
                                    }

                                    ul.ul1 {
                                    list-style-type: disc
                                    }
                                </style>
                                <p class="p1">
                                    <span class="s1"><strong>Scaffolding</strong>, also called <strong>
                                        scaffold</strong> or <strong>staging</strong>,</span>
                                    <span class="s2">
                                        <sup>[2]</sup>
                                    </span>
                                    <span class="s1">&nbsp;is a temporary structure used to support
                                        a work crew and materials to aid in the construction,
                                        maintenance and <a
                                            href="https://en.wikipedia.org/wiki/Repair">
                                            <span class="s3">repair</span>
                                        </a>
                                        of <a href="https://en.wikipedia.org/wiki/Buildings">
                                            <span class="s3">buildings</span>
                                        </a>,
                                        bridges and all other human-made structures. Scaffolds are
                                        widely used on site to get access to heights and areas that
                                        would be otherwise hard to get to.</span>
                                    <span class="s2">
                                        <sup>[3]</sup>
                                    </span>
                                    <span class="s1">&nbsp;Unsafe scaffolding has the potential to
                                        result in death or serious injury. Scaffolding is also used
                                        in adapted forms for formwork and shoring, grandstand
                                        seating, concert stages, access/viewing towers, exhibition
                                        stands, ski ramps, half pipes and art projects.</span>
                                </p>
                                <p class="p1">
                                    <span class="s1">There are six main types of scaffolding used
                                        worldwide today. These are <a
                                            href="https://en.wikipedia.org/wiki/Tube_and_clamp_scaffold">
                                            <span class="s3">tube and coupler</span>
                                        </a>
                                        (fitting) components, prefabricated modular system scaffold
                                        components, H-frame / façade modular system scaffolds,
                                        suspended scaffolds, timber scaffolds and bamboo scaffolds
                                        (particularly in China, India and Hong Kong). Each type is
                                        made from several components which often include:</span>
                                </p>
                                <ul class="ul1">
                                    <li class="li2">
                                        <span class="s1">A base jack or plate which is a
                                            load-bearing base for the scaffold.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">The standard, the upright component with
                                            connector joins.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">The ledger, a horizontal brace.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">The transom, a horizontal cross-section
                                            load-bearing component which holds the batten, board, or
                                            decking unit.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">Brace diagonal and/or cross section bracing
                                            component.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">Batten or board decking component used to
                                            make the working platform.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">Coupler, a fitting used to join components
                                            together.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">Scaffold tie, used to tie in the scaffold
                                            to structures.</span>
                                    </li>
                                    <li class="li2">
                                        <span class="s1">Brackets, used to extend the width of
                                            working platforms.</span>
                                    </li>
                                </ul>
                                <p class="p1">
                                    <span class="s1">Specialized components used to aid in their use
                                        as a temporary structure often include heavy duty load
                                        bearing transoms, ladders or stairway units for the ingress
                                        and egress of the scaffold, beams ladder/unit types used to
                                        span obstacles and rubbish chutes used to remove unwanted
                                        materials from the scaffold or construction project.</span>
                                </p>
                            </div>`
    },
];

export default blogOneData;
