import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';

function ContactItem({ icon, heading, text, items }) {
    const [isVisible, setIsVisible] = useState(false);
    const handleVisible = () => setIsVisible(true);
    const handleNotVisible = () => setIsVisible(false);
    return (
        <div className="col-lg-4 col-md-6 col-12">
            <div className="single-contact-card card1" onMouseEnter={handleVisible} onMouseLeave={handleNotVisible}>
                <div className="top-part">
                    <div className="icon">{icon}</div>
                    <div className="title">
                        <h4>{heading}</h4>
                        <span>{text}</span>
                    </div>
                </div>
                <div className="bottom-part">

                    { isVisible &&
                    <div className="info" style={{display: 'flex', flexDirection: 'column'}}>
                        {items.map((item, index) => (<a key={index} style={{textAlign: 'center', color: 'white', cursor: 'pointer'}} href={item.link}>{item.label}</a>))}
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ContactItem;
