import React from 'react';
import servicesTwoData from './servicesTwoData';
import ServicesTwoCard from './SevicesTwoCard';
import Logo from '../../assets/img/media/logo.webp';

function Services2() {
    return (
        <section className="our-service-wrapper section-padding mtm-30">
            <div className="container">
                <div className="row">
                    {servicesTwoData.map((data) => (
                        <ServicesTwoCard
                            key={data.id}
                            thumbnail={data.thumbnail}
                            icon={data.icon}
                            heading={data.heading}
                            text={data.text}
                        />
                    ))}
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                        <div style={{backgroundColor: "rgb(254, 188, 53)", height: "100%", width: "100%", padding: "2em", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", borderRadius: "15px"}}>
                            <h1 style={{textAlign: 'center'}}>Our Pride Is Your Satisfaction!</h1>
                            <img src={Logo} alt={'Logo'}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services2;
