/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import CategoryBtn from '../ProjectFilter/CategoryBtn';
import FilterItem from '../ProjectFilter/FilterItem';
import projectFilterTwoData from './projectFilterTwoData';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

function ProjectFilter2() {
    // STATES
    const [category, setCategory] = useState('all');
    const [filteredItem, setFilteredItem] = useState([]);

    useEffect(() => {
        category === 'all'
            ? setFilteredItem(projectFilterTwoData)
            : setFilteredItem(projectFilterTwoData.filter((data) => data.category === category));
    }, [category]);

    return (
        <section className="portfolio-wrapper-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12 text-center">
                        <div className="section-title-2">
                            <span>Portfolio</span>
                            <p>Portfolio</p>
                            <h1>Our Case Study</h1>
                        </div>
                    </div>
                </div>

                <div className="row mb-20">
                    <div className="col-12 col-lg-12 text-center">
                        <div className="portfolio-cat-filter">
                            <CategoryBtn
                                className={category === 'all' ? 'btn-active' : null}
                                name="all"
                                label="View All"
                                handleSetCategory={setCategory}
                            />
                            <CategoryBtn
                                className={category === 'asbestos' ? 'btn-active' : null}
                                name="asbestos"
                                label="Asbestos Abatement"
                                handleSetCategory={setCategory}
                            />
                              <CategoryBtn
                                className={category === 'asbestos' ? 'btn-active' : null}
                                name="asbestos"
                                label="Asbestos Abatement"
                                handleSetCategory={setCategory}
                            />



                            <CategoryBtn
                                className={category === 'lead' ? 'btn-active' : null}
                                name="lead"
                                label="Lead Removal"
                                handleSetCategory={setCategory}
                            />
                            <CategoryBtn
                                className={category === 'mold' ? 'btn-active' : null}
                                name="mold"
                                label="Mold"
                                handleSetCategory={setCategory}
                            />
                            <CategoryBtn
                                className={category === 'demolition' ? 'btn-active' : null}
                                name="demolition"
                                label="Demolition"
                                handleSetCategory={setCategory}
                            />
                            <CategoryBtn
                                className={category === 'restoration' ? 'btn-active' : null}
                                name="restoration"
                                label="Restoration Support"
                                handleSetCategory={setCategory}
                            />
                                                   
                            <CategoryBtn
                                className={category === 'waterfire' ? 'btn-active' : null}
                                name="waterfire"
                                label="Water & Fire Damage Clean Up"
                                handleSetCategory={setCategory}
                            />
                             <CategoryBtn
                                className={category === 'pipeinsulation' ? 'btn-active' : null}
                                name="pipeinsulation"
                                label="Pipe Insulation Removals And Set Up"
                                handleSetCategory={setCategory}
                            />
                             
                             <CategoryBtn
                                className={category === 'pipescaffolding' ? 'btn-active' : null}
                                name="pipescaffolding"
                                label="Pipe Scaffolding"
                                handleSetCategory={setCategory}
                            />
                            
                        </div>
                    </div>
                </div>

                <div className="row grid">

                    <Swiper spaceBetween={20} slidesPerView={4}>
                    {filteredItem.map(
                        (item) =>
                            (
                                <SwiperSlide>
                                    <FilterItem
                                        key={item.id}
                                        image={item.image}
                                        price={item.price}
                                        name={item.name}
                                        heading={item.heading}
                                        category={item.category}
                                    />
                                </SwiperSlide>
                            )
                    )}
                    </Swiper>
               
                </div>
            </div>
        </section>
    );
}

export default ProjectFilter2;
