import React from 'react';
import SinglePost from './SinglePost';

function BlogDetails() {

    return (
        <section className="blog-wrapper news-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="blog-post-details border-wrap">
                            <SinglePost />             
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogDetails;
