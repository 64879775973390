import React from 'react';
import { FaTh } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function MainMenu({ hanldeOffset }) {
    return (
        <>
            <div className="main-menu-area">
                <div className="row align-center">
                    <div className="col-6 col-md-12 col-lg-12">
                        <div className="main-menu">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/">Get In Touch</Link>
                                    <ul className='sub-menu'>
                                        
                                        <li>
                                            <a href="tel:+13177600250">(317) 760-0250</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/">Contact an Expert</Link>
                                    <ul className='sub-menu'>
                                        <li>
                                            <a href="tel:+13177600250">(317) 760-0250</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="tel:+13177600250">Schedule Pre-Quote</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainMenu;
