import React from 'react';

function WidgetGetinTouch({ heading, icon, children }) {
    const [show, setShow] = React.useState(false);
    const handleShow = () => setShow(true);
    const handleHide = () => setShow(false);
    return (
        <>
            <a className="single-contact-info" style={{cursor: 'pointer'}} onMouseEnter={handleShow} onMouseLeave={handleHide}>
                <div className="icon">{icon}</div>
                <div className="contact-info">
                <span>{heading}</span>
                    {(show && children.length > 0) && children.map((child, index) => <a key={index} style={{display: 'block'}} href={child.link}>{child.text}</a>)}
                    
                </div>
            </a>
        </>
    );
}

export default WidgetGetinTouch;
