import React from 'react';
import PolicyDoc from '../assets/docs/policy.pdf';


const PDFViewer = () => {
    return (
        <div style={{height: '100vh'}}>
            <iframe src={PolicyDoc} width='100%' height='100%' />
        </div>
    );
   };
   export default PDFViewer;