import React from 'react';
import man1 from '../../assets/img/man1.png';
import bgImg from '../../assets/img/map_pattern.png';
import FaqAccordion from '../FaqAccordion';
import { Fade } from 'react-reveal';

function Faq() {
    return (
        <section className="faq-section section-padding">
            <div className="container">
                <div className="row">
                    <Fade left>
                    <div className="col-xl-6 col-12">
                        <div className="content-block">
                            <p>Get Answers</p>
                            <h1>Get every single answers from here.</h1>
                            <div className="bg-img">
                                <img src={bgImg} alt="" />
                                <div
                                    className="man bg-cover man-1"
                                    style={{
                                        backgroundImage: `url(${man1})`,
                                    }}
                                />
                                <div
                                    className="man bg-cover man-2"
                                    style={{
                                        backgroundImage: `url(${man1})`,
                                    }}
                                />
                                <div
                                    className="man bg-cover man-3"
                                    style={{
                                        backgroundImage: `url(${man1})`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    </Fade>
                    <Fade right>
                    <div className="col-xl-6 col-12 mt-4 mt-xl-0">
                        <div className="faq-content">
                            <FaqAccordion
                                question="How do I know if I have asbestos in my home (in floor tile, ceiling tile, shingles, siding, etc.)?"
                                answer="The only way to be sure whether a material contains asbestos is to have it tested by a qualified
                                laboratory. EPA only recommends testing suspect materials if they are damaged (fraying,
                                crumbling) or if you are planning a renovation that would disturb the suspect material. Samples
                                should be taken by a properly trained and accredited asbestos professional (inspector). 
                                "
                            />
                            <FaqAccordion
                                question="What are the health risks if I have asbestos in my home, building, apartment, or school?"
                                answer="Asbestos that is in good condition and left undisturbed is unlikely to present a health risk. The risks
                                from asbestos occur when it is damaged or disturbed where asbestos fibers become airborne and
                                can be inhaled. Managing asbestos in place and maintaining it in good repair is often the best
                                approach. 
                                "
                            />
                            <FaqAccordion
                                question="My attic has vermiculite insulation in it. Am I at risk? Should I take it out?"
                                answer="If you have vermiculite insulation in your home, you should assume this material may be
                                contaminated with asbestos and be aware of steps you can take to protect yourself and your family
                                from exposure to asbestos. The EPA recommends that vermiculite insulation be left undisturbed.
                                Airborne asbestos fibers present a health risk through inhalation, so the first step is to not disturb
                                the material, which could release fibers into the air.
                                "
                            />
                            <FaqAccordion
                                question="When do you need to inspect a building/home for asbestos/lead?"
                                answer="Prior to any renovation the owner or operator must have all building materials affected by the renovation inspected for the presence of asbestos. Building materials that contain asbestos can be removed only by a certified asbestos worker. Some or all of the asbestos containing materials may have to be removed prior to beginning renovation activities."
                            />
                            <FaqAccordion
                                question="How do i get rid of the materials that contains Asbestos/lead on them?"
                                answer="Don't Worry! With our expert team certified on Asbestos/lead removal, rest assure that we are going to solve all your issues with asbestos.                                "
                            />
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
}

export default Faq;
