import React, { useState } from 'react';
import logo from '../../assets/img/media/logo.webp';
import WidgetAbout from './WidgetAbout';
import WidgetGetinTouch from './WidgetGetinTouch';
import WidgetGetinTouchData from './WidgetGetinTouchData';
import WidgetNews from './WidgetNews';
import WidgetNewsData from './WidgetNewsData';
import {Link} from 'react-router-dom';
import Map from '../ContactUs/Map';

function Footer1() {
    // STATES
    const [email, setEmail] = useState('');

    // HANDLER
    const onchangeHandler = (e) => {
        setEmail(e.target.value);
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
    };
    return (
        <footer className="footer-1 footer-wrap">
            <div className="footer-widgets dark-bg">
                <div className="container">
                    <div className="row">
                        {/* ABOUT WIDGET */}
                        <WidgetAbout
                            text="Clean Management works with all types of waste-generating entities, from small quantity generators to complex government enterprises. Our company functions in compliance with the Resource Conservation and Recovery Act (RCRA) and is equipped to manage Comprehensive Environmental Response, Compensation, and Liability Act (CERCLA) wastes. Clean Management is EPA compliant and has $15 million of liability insurance, zero debt, and can handle the entire documentation process for you. Let us handle the waste management, while you get back to doing what you do best."
                            fbLink="/"
                            twitterLink="/"
                            instaLink="/"
                            youtubeLink="/"
                        />

                        {/* NEWS FEED WIDGET */}

                        <div className="col-md-6 col-xl-3 col-12">
                            <div className="single-footer-wid recent_post_widget">
                                <div className="wid-title">
                                    <h4>Service Insights</h4>
                                </div>
                                <div className="recent-post-list">
                                    {WidgetNewsData.map((data) => (
                                        <WidgetNews
                                            key={data.id}
                                            thumbnail={data.thumbnail}
                                            meta={data.meta}
                                            heading={data.heading}
                                            link={data.link}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* NEWSLETTER WIDGET */}
                        <div className="col-md-6 col-xl-6 col-12">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="single-footer-wid ml-15 site_info_widget">
                                        <div className="wid-title">
                                            <h4>Area covered by us</h4>
                                        </div>
                                        <div className="area-covered">
                                            <h3 className='text-center'>We cover all the states around Indiana and much more!</h3>
                                            <Map/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="single-footer-wid ml-15 site_info_widget">
                                        <div className="wid-title">
                                            <h4>Get In Touch</h4>
                                        </div>
                                        <div className="contact-us" />
                                        {WidgetGetinTouchData.map((data) => (
                                            <WidgetGetinTouch
                                                key={data.id}
                                                icon={data.icon}
                                                heading={data.heading}
                                                children={data.children}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-md-5 pt-md-5">
                                <div className="col-12">
                                    <div style={{backgroundColor: "rgb(254, 188, 53)", width: "100%", textAlign: "center", color: "rgb(0, 0, 0)", fontWeight: "bold", fontSize: "30px", padding: "40px 20px", borderRadius: "10px", alignItems: "center", display: "flex", justifyContent: "center"}}>
                                        <span>Labors ready for you!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer bottom */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-12">
                            <div className="copyright-info">
                                <p>
                                    &copy; Copyright By <a href="index.html">TRM Staffing LLC</a> - 2024
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 text-center">
                            <div className="footer-logo">
                                <a href="#top">
                                    <img src={logo} alt="Dustrix" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 d-none d-lg-block col-12">
                            <div className="scroll-up-btn text-md-right justify-content-end">
                                <Link to='/policy'>Read our Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer1;
