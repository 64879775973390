import React from 'react';
import bannerDesktop from '../assets/img/media/services/banner-service-desktop.jpg';
import AboutFeatured from '../components/AboutFeatured';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import AboutGallery from '../components/AboutGallery';

function About() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerDesktop} currentPage="About Us" heading="About Us" />
            <AboutFeatured />
            <AboutGallery/>
            <Footer1 />
        </>
    );
}

export default About;
