import React from 'react';
import { BsPlus, BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ReactModal from 'react-modal';


function ServicesOneCard({ bgImg, icon, heading, description, btnText }) {

  return (
    <div className="col-md-6 col-xl-4 col-12">
      <div className="single-service-item service-1">
        <div
          className="service-bg bg-cover"
          style={{
            backgroundImage: `url(${bgImg})`,
          }}
        />
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <h3>{heading}</h3>
        <Link to='#'>
          <span>{btnText}</span>
          <BsPlus style={{ fontSize: '18px' }} />
        </Link>
      </div>
    </div>
  );
}

export default ServicesOneCard;
