import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/media/blog/blog1.webp';
import thumb2 from '../../assets/img/media/blog/blog2.webp';
import thumb3 from '../../assets/img/media/blog/blog3.webp';
import thumb4 from '../../assets/img/media/blog/blog4.webp';
import thumb5 from '../../assets/img/media/blog/blog5.webp';


const WidgetNewsData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        meta: '24th November 2020',
        heading: 'Know More About Asbestos Abatement',
        link: '/news-details',
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        meta: '24th November 2020',
        heading: 'Especialized Clean Up Services',
        link: '/news-details',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        meta: '24th November 2020',
        heading: 'Excellence in Demolition Services',
        link: '/news-details',
    },
    {
        id: uuidv4(),
        thumbnail: thumb4,
        meta: '20 Jun',
        heading: 'Pipe Insulations Setting Up Labors',
        link: '/news-details',
    },
    {
        id: uuidv4(),
        thumbnail: thumb5,
        meta: '20 Jun',
        heading: 'Metal Tanks Insulation Removal Labors',
        link: '/news-details',
    },
];


export default WidgetNewsData;
