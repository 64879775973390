import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/media/logo.webp';
import MobileMenu from '../MobileMenu';
import OffsetMenu from '../OffsetMenu';
import MainMenu from './MainMenu';
import DropdownButton from '../DropdownButton';

function Header1() {
    const [offset, setOffset] = useState(false);
    const handleOffset = () => {
        setOffset(!offset);
    };

    const contactList = [
       
        {
            id: 2,
            link: 'tel:+13177600250',
            label: '(317) 760-0250'
        },
    ];
    return (
        <>
            <OffsetMenu
                handleOffset={handleOffset}
                className={offset ? 'offset-menu show' : 'offset-menu'}
            />
            <header className="header-wrap header-1">
                {/* <Topbar /> */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-8 col-lg-3">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-4 d-block d-lg-none">
                            <div className="mobile-nav-wrap">
                                <MobileMenu />
                            </div>
                        </div>
                        <div className="col-12 d-none d-md-flex col-lg-9">
                            <div style={{backgroundColor: "rgb(254, 188, 53)", width: "100%", textAlign: "center", color: "rgb(0, 0, 0)", fontWeight: "bold", fontSize: "30px", padding: "20px", borderRadius: "10px", alignItems: "center", display: "flex", justifyContent: "center", alignSelf: "center"}}>
                                <span>
                                        Labors ready for you!
                                </span>
                            </div>
                            <div className="header-contact-info text-lg-right">
                                <div className="single-element">
                                    <a href="mailto:trmstaffing059@gmail.com" style={{cursor: 'pointer'}}>Want to book an appointment?</a>
                                    <span>Mail Us Now</span>
                                </div>
                                <div className="header-btn d-inline">
                                    <DropdownButton title="Get In Contact" items={contactList}></DropdownButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-wrapper d-none d-lg-block">
                    <div className="container">
                        <MainMenu hanldeOffset={handleOffset} />
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header1;
