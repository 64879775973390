import React, { useState } from 'react';

function DropdownButton(props) {

    const [isVisible, setIsVisible] = useState(false);

    const mouseEnterHandler = () => {
        setIsVisible(true);
    }

    const mouseLeaveHandler = () => {
        setIsVisible(false);
    }
    return (
        <div className='dropdown-theme' onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
            <button className='theme-btn dropdown-button'  type="button">{props.title}</button>
            <div className={'dropdown-button__options-container' + (isVisible ? '' : 'not-visible')}>
                 { isVisible && props.items.map((item) => (<a key={item.id} className='dropdown-button__option' href={item.link}>{item.label}</a>))}
            </div> 
        </div>
    )
}


export default DropdownButton;