import React from 'react';
import blogOneData from './BlogOneData';
import BlogOneItem from './BlogOneItem';
import Logo from '../../assets/img/media/logo.webp';
import { Slide } from 'react-reveal';

function Blog1() {
    return (
        <section className="blog-section section-padding">
            <Slide bottom>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                            {/* <span>News</span> */}
                            {/* <p>News Feed</p> */}
                            <h1>Service Insights</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {blogOneData.map((data) => (
                        <BlogOneItem
                            key={data.id}
                            thumb={data.thumbnail}
                            date={data.date}
                            month={data.month}
                            category={data.category}
                            author={data.author}
                            title={data.title}
                            link={data.link}
                            content={data.content}
                        />
                    ))}
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div style={{width: "100%", height: "87%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(254, 188, 53)", borderRadius: "10px", marginTop: "60px"}}>
                            <img src={Logo} alt="Logo" height="100"/>
                        </div>
                    </div>
                </div>
            </div>
            </Slide>
        </section>
    );
}

export default Blog1;
