import React, { useState } from 'react';
import { FaSearch, FaTh } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo-2.png';
import MobileMenu from '../MobileMenu';
import OffsetMenu from '../OffsetMenu';

function MainMenu2() {
    const [offset, setOffset] = useState(false);
    const handleOffset = () => {
        setOffset(!offset);
    };
    return (
        <>
            <OffsetMenu
                handleOffset={handleOffset}
                className={offset ? 'offset-menu show' : 'offset-menu'}
            />
            <header className="header-wrap header-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="logo">
                                <Link to="/home-2">
                                    <img src={logo} alt="logo" />
                                </Link> 
                            </div>
                            <div className="menu-wrapper">
                                <div className="main-menu">
                                <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/">Get In Touch</Link>
                                    <ul className='sub-menu'>
                                        <li>
                                            <a href="tel:+13174965682">(317) 496-5682</a>
                                        </li>
                                        <li>
                                            <a href="tel:+13177600250">(317) 760-0250</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/">Contact an Expert</Link>
                                    <ul className='sub-menu'>
                                        <li>
                                            <a href="tel:+13177600250">(317) 760-0250</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="tel:+13177600250">Schedule Pre-Quote</a>
                                </li>
                            </ul>
                                </div>
                            </div>
                            <div className="header-right">
                                <Link to="/contact" className="theme-btn theme-2">
                                    Make Appointment
                                </Link>
                                <Link to="/home-2" className="serach-btn circle-btn">
                                    <FaSearch />
                                </Link>
                                <button
                                    type="button"
                                    style={{ cursor: 'pointer' }}
                                    className="offcanvas-btn circle-btn"
                                    onClick={handleOffset}
                                >
                                    <FaTh />
                                </button>
                            </div>
                            <div className="mobile-menu-wrap align-items-center justify-content-center">
                                <div className="mobile-nav-wrap">
                                    <div id="hamburger">
                                        <i className="fal fa-bars" />
                                    </div>

                                    <div className="mobile-nav">
                                        <MobileMenu />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default MainMenu2;
