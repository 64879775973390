import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import 'photoswipe/dist/photoswipe.css';


const importAllImages = (requireContext) => {
    return requireContext.keys().map(requireContext);
}

const images = importAllImages(require.context('../../assets/img/gallery', false, /\.(png|jpe?g|svg)$/));

function AboutGallery() {
    return (
        <div className="gallery">
            <Gallery>
                {images.map((image, index) => (
                <Item
                    key={index}
                    original={image.default}
                    thumbnail={image.default}
                    title={`Imagen ${index + 1}`}
                >
                    {({ ref, open }) => (
                        <img
                            ref={ref}
                            onClick={open}
                            src={image.default}
                            alt={`Imagen ${index + 1}`}
                            style={{ cursor: 'pointer', width: '167px', height: '180px', objectFit: 'cover', margin: '2px' }}
                        />
                    )}
                </Item>
            ))}
        </Gallery>
        </div>
    )
}

export default AboutGallery;