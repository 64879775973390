import React from 'react';

function DescriptionBar() {
    return (
        <>
            <div className="descriptionbar-container">
                <div className="row">
                    <div className="col-12 col-md-3 col-lg-3 descriptionbar-item green">SATISFACTION GUARANTEE</div>
                    <div className="col-12 col-md-3 col-lg-3 descriptionbar-item red">CERTIFIED PROFESSIONALS</div>
                    <div className="col-12 col-md-3 col-lg-3 descriptionbar-item blue">SECURE PAYMENTS</div>
                    <div className="col-12 col-md-3 col-lg-3 descriptionbar-item purple">LICENSED AND INSURED</div>
                </div>
            </div>
        </>
    )
}

export default DescriptionBar
