import { FaCity, FaDraftingCompass, FaHardHat, FaRegBuilding, FaTruckMoving } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/media/services/service1.webp';
import thumb2 from '../../assets/img/media/services/service2.webp';
import thumb3 from '../../assets/img/media/services/service3.webp';
import thumb4 from '../../assets/img/media/services/service4.webp';
import thumb5 from '../../assets/img/media/services/service5.webp';
import thumb6 from '../../assets/img/media/services/service6.jpeg';
import thumb7 from '../../assets/img/media/services/service7.webp';
import thumb8 from '../../assets/img/media/services/service8.webp';

const servicesTwoData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        icon: <FaDraftingCompass />,
        heading: 'Asbestos Abatement',
        text: 'An asbestos removal service involves the safe and thorough removal of asbestos-containing materials from buildings, following strict protocols to prevent the release of hazardous fibers. Trained professionals conduct assessments, seal off work areas, use specialized equipment to remove asbestos, and ensure proper disposal in accordance with regulations. This service is essential for minimizing the health risks associated with asbestos exposure and ensuring the safety of building occupants.',
    },

    { 
        id: uuidv4(),
        thumbnail: thumb2,
        icon: <FaRegBuilding />,
        heading: 'Lead Removal',
        text: 'Lead removal is the safe and meticulous process of removing lead-containing materials, such as lead-based paint, pipes, or solder, from buildings and structures to prevent lead exposure and associated health risks.',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        icon: <FaTruckMoving />,
        heading: 'Mold Remediation',
        text: 'Mold remediation involves the comprehensive process of identifying, containing, removing, and preventing the recurrence of mold growth in indoor environments. Professionals conduct thorough inspections to pinpoint the extent of mold contamination and the underlying moisture source, followed by isolating the affected area and repairing the moisture source. The actual remediation includes meticulous cleaning and disinfection of mold-infested surfaces, along with measures to improve air quality and prevent future mold growth. This process is crucial for safeguarding indoor air quality and mitigating potential health risks associated with mold exposure.',
    },

    {
        id: uuidv4(),
        thumbnail: thumb4,
        icon: <FaRegBuilding />,
        heading: 'Demolition Services',
        text: 'Demolition services specialized in asbestos and lead removal are crucial for safely and effectively eliminating hazardous materials from buildings and structures. These services involve expert identification, containment, and removal of asbestos and lead-based materials, carefully following strict protocols to prevent the release of harmful particles. Prioritizing safety, these services adhere to regulations, employ proper containment measures, and implement specialized techniques to protect workers, occupants, and the environment. Additionally, they emphasize environmental considerations, aiming to minimize the impact by responsibly disposing of materials in accordance with regulations. Overall, these specialized demolition services require expertise and training to ensure the safe removal of hazardous materials, safeguarding the health of occupants and the surrounding environment.',
    },

    {
        id: uuidv4(),
        thumbnail: thumb5,
        icon: <FaCity />,
        heading: 'Restoration Support',
        text: 'Our specialized Pipe Scaffolding services are designed to provide safe and efficient access in hazardous environments, Our team of experts is trained in handling scaffolding in a variety of environments, ensuring safety and compliance with regulations. We offer tailored scaffolding solutions to suit the specific requirements of asbestos and lead environments, ensuring minimal disturbance and maximum safety strictly adhiring to safety protocols to protect workers and our valuable clients.',
    },

    {
        id: uuidv4(),
        thumbnail: thumb6,
        icon: <FaHardHat />,
        heading: 'Water & Fire Damage Clean Up',
        text: 'A water and fire damage cleanup service specializes in restoring properties that have been affected by water and fire emergencies. These services play a crucial role in mitigating further damage, salvaging belongings, and returning the property to a safe and habitable condition.',
    },
    {
        id: uuidv4(),
        thumbnail: thumb7,
        icon: <FaHardHat/>,
        heading: 'Pipe Insulation Removals and Set Up',
        text: "TRM STAFFING LLC's certified professionals specialize in providing expert labor for industrial projects, including the removal and installation of pipe insulation. We also handle a variety of infrastructure components such as thermal system insulation, tank insulation, structural fireproofing, duct insulation, floor tiles and mastic, transit panels, lab hoods, caulking/glazing, and sheetrock. Our commitment is to provide qualified, certified, and high-quality labor that ensures safety and efficiency in your projects, with a focus on excellence and professionalism tailored to the specific needs of our clients."
    },
    {
        id: uuidv4(),
        thumbnail: thumb8,
        icon: <FaHardHat/>,
        heading: 'Pipe Scaffolding',
        text: "At our company, we specialize in providing expert labor for the installation and assembly of Pipe scaffolding systems. We focus on delivering quality service to ensure safety and efficiency in your projects. Our commitment is to offer specialized labor with a focus on excellence and professionalism in every job performed, adapting to the needs of our clients."
    }
];

export default servicesTwoData;
