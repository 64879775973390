import React, { useState } from 'react';
import { FaComments } from 'react-icons/fa';
import bg from '../../assets/img/subscribe_bg.jpg';
import img from '../../assets/img/media/banner.webp';
import {Link} from 'react-router-dom';
import {Fade} from 'react-reveal';

function Subscribe() {
    // States
    const [email, setEmail] = useState('');
    // Handler
    const onChangeHandler = (e) => {
        setEmail(e.target.value);
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
    };

    return (
        <Fade>
        <section
            className="subscribe-box-wrapper text-white bg-overlay section-padding bg-cover" style={{backgroundColor: 'black'}}
        >
            <div
                className="subscribe_left_bg d-none d-xl-block bg-cover"
                style={{
                    backgroundImage: `url(${img})`,
                }}
            />
            <div className="container" style={{minHeight: 300}}>
                <div className="row">
                    <div className="col-xl-7 col-12 offset-xl-5 d-flex align-items-center flex-column justify-content-center">
                        <h1>TRM Staffing LLC</h1>
                        <span>Your best choice for a clean and healthy environment for you and your beloved ones.</span>

                        <Link to='/contact' className="theme-btn my-5">Get in touch with us</Link>
                    </div> 
                </div>
            </div>
        </section>
        </Fade>
    );
}

export default Subscribe;
