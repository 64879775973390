/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import FormInput from './FormInput';
import { Link } from 'react-router-dom';
import data from './../../assets/docs/states.json';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alerts = withReactContent(Swal);



function ContactForm({ title, heading }) {
    const [states, setStates] = useState({});

    // STATES
    const [message, setMessage] = useState('');
    const [agree, setAgree] = useState(false);
    const [fullname, setFullname] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        setStates(data);
    }, [states]);

    // HANDLERS
    const onChangeHandler = (e) => {
        setMessage(e.target.value);
    };

    const onAgreeHandler = () => {
        setAgree(!agree);
    };

    const onSubmitHandler = () => {
        // Crear el cuerpo del mensaje con los datos del formulario
        const formData = {
            name: fullname,
            phone: phone,
            city: city,
            state: state,
            message: message,
        };

        fetch('https://api.trmstaffingllc.com/api/send-email', { // Reemplaza 'path/to/mail.php' con la ruta correcta
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData), // Convierte los datos del formulario a JSON
        })
        .then(response => response.json()) // Analiza la respuesta como JSON
        .then(data => {
            if (data.success) {
                Alerts.fire({
                    title: 'Success',
                    html: 'Your contact information has been sent successfuly.',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    icon: 'success'
                });
                setFullname('');
                setPhone('');
                setCity(null);
                setState(null);
                setMessage('');
                setAgree(false);
            } else {
                Alerts.fire({
                    title: 'Something got wrong!',
                    html: 'Your contact information was not able to be sent',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    icon: 'error'
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Hubo un problema al enviar el mensaje.');
        });
    };

    return (
        <section className="contact-form-wrapper section-padding pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mb-20">
                        <span>{title}</span>
                        <h1>{heading}</h1>
                    </div>
 
                    <div className="col-12 col-lg-12">
                        <div className="contact-form">
                            <form className="row conact-form">
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor={'fullname'}>{'Full Name or Company Name'}</label>
                                        <input
                                            required={true}
                                            value={fullname}
                                            onChange={(event) => setFullname(event.target.value)}
                                            type={'text'}
                                            placeholder={'Enter Name or Company Name'}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor={'phone'}>{'Phone Number'}</label>
                                        <input
                                            required={true}
                                            value={phone}
                                            onChange={(event) => setPhone(event.target.value)}
                                            type={'text'}
                                            id={'phone'}
                                            placeholder={'Enter Phone Number'}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor={'state'}>{'State'}</label>
                                        <select id={'state'}
                                                required={true}
                                                value={state}
                                                onChange={(event) => setState(event.target.value)}
                                                aria-placeholder={'Select State'}
                                        >
                                            <option value={null} disabled={true}>Select State</option>
                                            {Object.keys(states).map((item, index) => <option key={index}
                                                                                              value={item}>{item}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor={'city'}>{'Enter City'}</label>
                                        <select id={'city'}
                                                required={true}
                                                value={city}
                                                onChange={(event) => setCity(event.target.value)}
                                                aria-placeholder={'Select City'}
                                                disabled={ !state || state === ''}
                                        >
                                            <option value={null} disabled={true}>Select City</option>
                                            {state && states[state].map((item, index) => <option key={index} value={item}>{item}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-12 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor="subject">Enter Message</label>
                                        <textarea
                                            value={message}
                                            onChange={onChangeHandler}
                                            id="subject"
                                            placeholder="Enter message"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 col-12 d-flex align-items-center justify-content-center pb-4">
                                    <input name="terms-agree" checked={agree} type="checkbox"
                                           onChange={onAgreeHandler}/>
                                    <label htmlFor="terms-agree" style={{paddingLeft: 10, margin: 0}}>
                                        I have read and agree to the <Link to='/policy' style={{
                                        color: '#febc35',
                                        fontSize: 14,
                                        fontWeight: 'bold'
                                    }}>terms and conditions.</Link>
                                    </label>
                                </div>

                                <div className="col-md-12 col-12 text-center">
                                    <button
                                        className="submit-btn"
                                        type="button"
                                        onClick={onSubmitHandler}
                                        disabled={!agree}
                                    >
                                        Get A Quote
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;
