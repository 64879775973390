import React, { useState } from 'react';
import { FaHardHat, FaPlay, FaRoad } from 'react-icons/fa';
import { FiAward } from 'react-icons/fi';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import aboutImg from '../../assets/img/about_us.jpg';
import btnImg from '../../assets/img/skill_bg.jpg';
import IconBox from './IconBox';

function AboutFeatured() {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="OBoj6vl4hLg"
                onClose={() => setOpen(false)}
            />


            <section className="about-featured-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-12">
                            <div
                                className="about-promo bg-cover"
                                style={{ backgroundImage: `url(${aboutImg})` }}
                            >
                                <div
                                    className="skill-popup-video d-flex justify-content-center align-items-center bg-cover"
                                    style={{ backgroundImage: `url(${btnImg})` }}
                                >
                                    <div className="video-play-btn">
                                        <button
                                            type="button"
                                            className="modal-btn"
                                            onClick={() => setOpen(true)}
                                        >
                                            <FaPlay />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                            <div className="block-contents ml-lg-5">
                                <span>Bringing a better life with a healthier house</span>
                                <h1>TRM Staffing LLC</h1>
                                <h4>
                                    We manage the hard work and the risks for you to keep your family safe
                                </h4>
                            </div>

                            <div className="icon-boxs ml-lg-5">
                                <IconBox
                                    icon={<FiAward />}
                                    heading="Certified & Insured"
                                    text="100% Satisfaction Guarantee with our certified professionals in the field."
                                />
                                <IconBox
                                    icon={<FaHardHat />}
                                    heading="Safe Work Environment"
                                    text="All the security measures to keep your family and our workers safe on every job."
                                />
                            </div>
                        </div>

                        <div className="row text-center pt-5">
                            <h2>Trust TRM STAFFING LLC</h2>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6"><br></br>If your Organization needs to hire Asbestos or demo Cleaning
                                temporary Staff, you can find first rate candidates and experienced asbestos abatement contacting
                                us.<br></br><br></br>When you choose TRM STAFFING LLC we are going to:<br></br><br></br>Work, Find and Train Candidates who meet
                                your job especifications. As a Company with experience on this field we offer candidates with the knowledge
                                of the asbestos industry policies and safety requirements in order to be highly skilled at setting up
                                asbestos containments, removing hazardous materials, and protecting themselves from work place hazards.
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-6"><br></br>With over 10 years of Asbestos, Lead and Mold removal
                                experience, we know exactly what type of workers you need. More importantly we have certifications and
                                requirements to help you out with any future proyect<br></br><br></br>Feel free to contact us through any of the
                                contacts sites availables from the info described right below.<br></br><br></br>Thank you for your trust and we will
                                do everything to keep your standards and requirements on set at the job site.<br></br>
                            </div>
                        </div>
                    </div>
                </div>









            </section>
        </>
    );
}

export default AboutFeatured;
