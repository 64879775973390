import React from 'react';
import Analytics from '../components/Analytics';
import Blog1 from '../components/Blog1';
import Cta from '../components/Cta';
import Faq from '../components/Faq';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import Hero1 from '../components/Hero1';
import Portfolio1 from '../components/Portfolio1';
import Pricing from '../components/Pricing';
import Promo from '../components/Promo';
import Services1 from '../components/Services1';
import Subscribe from '../components/Subscribe';
import DescriptionBar from '../components/DescriptionBar';
import ChooseUs from '../components/ChooseUs';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alerts = withReactContent(Swal);

const alert = Alerts.fire({
  title: 'Are you 21 or older?',
  html: 'You must be of legal age to view our website. Due to legal requirements you must verify your age.',
  confirmButtonText: 'I am over 21',
  showDenyButton: true,
  denyButtonText: 'Exit',
  customClass: 'age-verification-dialog',
  allowOutsideClick: false,
});

alert.then((result) => {
  if (result.isDenied) {
    window.location.href = 'https://google.com?q=TRM Staffing LLC';
  }
});

function Home1() {
  return (
    <>
      <Header1 />
      <Hero1 />
      <DescriptionBar />
      <ChooseUs />
      <Promo />
      <Services1 />
      <Cta />
      <Faq />
      <Portfolio1 />
      {/* <Analytics /> */}
      <Subscribe />
      <Blog1 />
      <Footer1 />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="tmrstaffingllc"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </>
  );
}

export default Home1;
