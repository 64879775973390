import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email Address',
        text: 'Sent mail asap anytime',
        items: [
            {
                link: 'mailto:trmstaffing059@gmail.com',
                label: 'trmstaffing059@gmail.com'
            }
        ]
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Phone Number',
        text: 'call us asap anytime',
        items: [
            {
                link: 'tel:+13177600250',
                label: '(317) 760-0250',
            },
           
        ]
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'Covered Zone',
        text: 'We cover all the states around Indiana and much more!',
        items: [
            {
                link: '',
                label: ' '
            },
        ]
    },
];

export default contactData;
