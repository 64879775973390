import { v4 as uuidv4 } from 'uuid';

const processItemData = [
    {
        id: uuidv4(),
        number: '01',
        heading: 'Assessment',
        text: 'Trained professionals will assess the extent of asbestos, mold, lead, demolition, insulation and pipes removal to determine the appropriate removal methods.',
    },

    {
        id: uuidv4(),
        number: '02',
        heading: 'Preparation and Containment',
        text: 'Before starting the removal process, the work area is prepared to minimize the spread of asbestos fibers. This involves sealing off the area with plastic sheeting and setting up negative air pressure systems to prevent the escape of asbestos particles.',
    },

    {
        id: uuidv4(),
        number: '03',
        heading: 'Wet Removal',
        text: 'Asbestos removal typically involves wet methods to minimize the release of fibers into the air. Wetting the ACMs helps to reduce the risk of fiber dispersion during the removal process. Specialized tools and equipment are used to carefully remove the asbestos-containing materials.',
    },

    {
        id: uuidv4(),
        number: '04',
        heading: 'Documentation and Reporting',
        text: 'A comprehensive report is prepared, documenting the entire asbestos removal process, including inspection results, removal methods, waste disposal records, air monitoring data, and clearance test results. This documentation is important for compliance and future reference.',
    },
];

export default processItemData;
