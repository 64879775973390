import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/media/services/service1.webp';
import Img2 from '../../assets/img/media/services/service2.webp';
import Img3 from '../../assets/img/media/services/service3.webp';
import Img4 from '../../assets/img/media/services/service4.webp';
import Img5 from '../../assets/img/media/services/service5.webp';
import Img6 from '../../assets/img/media/services/service6.jpeg';
import Img7 from '../../assets/img/media/services/service7.webp';
import Img8 from '../../assets/img/media/services/service8.webp';

const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: '',
        client: '',
        heading: 'Asbestos Abatement',
        desc: 'An asbestos abatement service involves the identification, containment, removal, and proper disposal of asbestos-containing materials (ACMs) in buildings or structures. Asbestos is a naturally occurring mineral that was widely used in construction for its fire-resistant and insulating properties. However, it poses serious health risks when its fibers become airborne and are inhaled.',
        btnText: 'Service Details',
    },

    {
        id: uuidv4(),
        image: Img2,
        category: '',
        client: '',
        heading: 'Lead Removal',
        desc: 'Lead removal is the safe and meticulous process of removing lead-containing materials, such as lead-based paint, pipes, or solder, from buildings and structures to prevent lead exposure and associated health risks.',
        btnText: 'Service Details',
    },

    {
        id: uuidv4(),
        image: Img3,
        category: '',
        client: '',
        heading: 'Mold Remediation',
        desc: 'A mold remediation service involves the identification, containment, removal, and prevention of mold growth within indoor environments. Mold can pose health risks and compromise the structural integrity of buildings. Mold remediation aims to address existing mold issues and prevent future growth.',
        btnText: 'Service Details',
    },

    {
        id: uuidv4(),
        image: Img4,
        category: '',
        client: '',
        heading: 'Demolition Services',
        desc: 'A demolition service involves the systematic dismantling, tearing down, or removal of structures, buildings, or any unwanted constructions. Demolition projects vary in scale, from small residential buildings to large commercial or industrial structures.',
        btnText: 'Service Details',
    },

    {
        id: uuidv4(),
        image: Img5,
        category: '',
        client: '',
        heading: 'Restoration Support',
        desc: 'A restoration support service involves providing comprehensive assistance to individuals or businesses in the aftermath of a disaster, such as fire, flood, storm, or other events causing damage to property. The goal is to help restore and rebuild the affected property, mitigating further damage and facilitating the recovery process.',
        btnText: 'Service Details',
    },
    {
        id: uuidv4(),
        image: Img6,
        category: '',
        client: '',
        heading: 'Water & Fire Damage Clean Up',
        desc: 'A water and fire damage cleanup service specializes in restoring properties that have been affected by water and fire emergencies. These services play a crucial role in mitigating further damage, salvaging belongings, and returning the property to a safe and habitable condition. ',
        btnText: 'Service Details',
    },
    {
        id: uuidv4(),
        image: Img7,
        category: '',
        client: '',
        heading: 'Pipe Insulation Removals and Set Up',
        desc: "TRM STAFFING LLC's certified professionals specialize in providing expert labor for industrial projects, including the removal and installation of pipe insulation. We also handle a variety of infrastructure components such as thermal system insulation, tank insulation, structural fireproofing, duct insulation, floor tiles and mastic, transit panels, lab hoods, caulking/glazing, and sheetrock. Our commitment is to provide qualified, certified, and high-quality labor that ensures safety and efficiency in your projects, with a focus on excellence and professionalism tailored to the specific needs of our clients.",
        btnText: 'Service Details'
    },
    {
        id: uuidv4(),
        image: Img8,
        category: '',
        client: '',
        heading: 'Pipe Scaffolding',
        desc: 'At our company, we specialize in providing expert labor for the installation and assembly of Pipe scaffolding systems. We focus on delivering quality service to ensure safety and efficiency in your projects. Our commitment is to offer specialized labor with a focus on excellence and professionalism in every job performed, adapting to the needs of our clients.',
        btnText: 'Service Details'
    }
];

export default portfolioOneData;
