import React from 'react';
import { AiOutlinePhone } from 'react-icons/ai';
import BgImg from '../../assets/img/subscribe_bg.jpg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Fade } from 'react-reveal';

function Cta() {
    return (
        <section className="cta-wrapper">
            <div className="container">
                <Fade>
                <div
                    className="cta-content bg-cover"
                    style={{
                        backgroundImage: `url(${BgImg})`,
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-xl-7 pl-xl-3 col-12 text-center text-xl-left">
                            <h1 className="cta-heading">Get a quote, make an appointment or get consultation specialized for your needs</h1>
                        </div>
                        <div className="col-xl-4 pl-xl-0 mt-4 mt-xl-0 col-12 text-center text-xl-left">
                            <div className="contact-info">
                                <div className="contact-number">
                                    <Link to="/contact" className="theme-btn" style={{backgroundColor: 'black', color: 'white'}}>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Fade>
            </div>
        </section>
    );
}

export default Cta;
