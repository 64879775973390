import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import HeroBg1 from '../../assets/img/media/hero.webp';
import DropdownButton from '../DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

function Hero1() {

    const contactList = [
        
        {
            id: 2,
            link: 'tel:+13177600250',
            label: '(317) 760-0250'
        },
    ];

    return (
        <section className="hero-slide-wrapper hero-1">
            <Swiper className="hero-slider-active owl-carousel">
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg1})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-8">
                                    <div className="hero-contents">
                                        <h1>TRM STAFFING LLC</h1>
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                            <DropdownButton title="Get In Touch" items={contactList}/>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">

                                            <Link to="/about" className="theme-btn w-100 text-center">
                                            Secure Payments
                                        </Link>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                            <Link to="/contact" className="theme-btn w-100 text-center">Pricesmatch Guarantee</Link>

                                            </div>
                                        </div>
                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg1})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1>TRM STAFFING LLC</h1>
                                        <a href="services.html" className="theme-btn">
                                            Free Estimates
                                        </a>
                                        <a href="about.html" className="theme-btn black">
                                            learn more
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default Hero1;
