import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ValuesImg from '../../assets/img/media/values.webp';
import {Fade} from 'react-reveal';


const ChooseUs = (props) => {
    return (
        <>
        <div className="container py-5">
            <div className="row">
                <Fade left>
                <div className="col-12 col-md-6 col-lg-6">
                    <h1>Why Choose Us?</h1>
                    <ul className="choose-items py-3">
                        <li>
                        <b>Specialized Experience:</b> With years of experience in asbestos management and demolition, our team is highly trained to tackle any project, from small renovations to large-scale demolition projects.
                        </li>
                        <li>
                        <b>Commitment to Safety:</b> Safety is our top priority. We adhere to the strictest regulations and employ advanced techniques to ensure a safe working environment for our employees and clients.
                        </li>
                        <li>
                        <b>Certified Experts:</b> Our team consists of certified experts in asbestos management and demolition. We stay up-to-date with the latest techniques and regulations to deliver the highest quality service.
                        </li>
                        <li>
                        <b>Excellence in Customer Service:</b> Our team is dedicated to providing exceptional customer service at every stage of the project. We strive to exceed expectations and ensure customer satisfaction at all times.
                        </li>
                    </ul>

                    <p className="pb-4">
                    By choosing us, you can rest assured that you are entrusting your project to dedicated professionals committed to excellence. Contact us today to discuss how we can assist you with your asbestos management, demolition, and more.
                    </p>

                    <Link to="/about" className="theme-btn">Read More</Link>
                </div>
                </Fade>
                <Fade right>
                <div className="col-12 col-md-6 col-lg-6">
                    <img src={ValuesImg} alt="TRM Values"></img>
                </div>
                </Fade>
            </div>
        </div>
        </>
    )
}

export default ChooseUs;
