import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';

const WidgetGetinTouchData = [
    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Get In Contact',
        children: [
            {
                text: '(317) 760-0250',
                link: 'tel:+13177600250'
            },
           
        ]
       
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Contact an expert',
        children: [
            {
                text: '(317) 760-0250',
                link: 'tel:+13177600250'
            },
           
        ]
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Get a quote',
        children: [
            {
                text: '(317) 760-0250',
                link: 'tel:+13177600250'
            },
           
        ]
    },
];

export default WidgetGetinTouchData;
